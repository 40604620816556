<script setup>
  import { computed, ref } from 'vue'
  import { useBackend } from '@/composables/Backend';

  const backend = useBackend();

  // get all the form values
  const firstName = ref('');
  const lastName = ref('');
  const email = ref('');
  const password = ref('');
  const confirmPassword = ref('');
  const whereDidYouHearAboutUs = ref('');
  const signupToNewsletter = ref(false);
  const signupToken = ref(''); // TODO: this is set IF a user checks out before creating an account. This should populate a signUp toekn from Shopify that attaches to a user's email!
  const isWorking = ref(false);
  const isSuccess = ref(false);
  const error = ref(false);

  // if we add a lot more sources then we should do this better, but for now it's fine
  const firstColumnOfSources = [
    { id: 'facebook', name: 'Facebook' },
    { id: 'instagram', name: 'Instagram' },
    { id: 'pinterest', name: 'Pinterest' },
    { id: 'press', name: 'Press' },
    { id: 'google', name: 'Google' },    
  ];  
  const secondColumnOfSources = [    
    { id: 'word-of-mouth', name: 'Word of Mouth' },
    { id: 'home-polish', name: 'Home Polish' },
    { id: 'interior-designer', name: 'Interior Designer' },
    { id: 'other', name: 'Other' },
  ];

  const submitButtonText = computed(() => {
    if (isWorking.value) {
      return 'Please wait...';
    } 
    else {
      return 'Create Account →';
    }
  });

  const submitButtonCss = computed(() => {
    var css = 'button primary__btn w-button';
    if (isWorking.value) {
      css + ' is--waiting ';
    }    
    return css;
  });

  function submitForm() {
    isWorking.value = true;

    console.log('signupToken', signupToken)
        
    // make call to backend
    backend.createAccount(firstName.value, lastName.value, email.value, password.value, confirmPassword.value, whereDidYouHearAboutUs.value, signupToNewsletter.value, signupToken.value)
      .then(result => {
        if (result == false || result.error) {
          // handle error
          error.value = result.error;
          if (!error.value) {
            error.value = 'An error occurred communicating with the API.';
          }
          error.value = `Oops! An error occurred during account creation: ${error.value}`;
          console.error(error);
        }
        else {
          // signup successful
          isSuccess.value = true;
        }
        isWorking.value = false;
      });             
  }
</script>

<template>
  <div class="section__container section__flex alt">
    <div class="section__grid-col grid-col--50">
      <div class="div-block-4 load-1">
        <h2>Create a New Account</h2>
        <div class="dashed-divider"></div>
        <p class="no--margin-b">
          Don't have an account yet? <br>
          <a data-w-id="4ac35aa7-2cc0-4d1d-af4c-66b0e5a9c84c" href="#" class="link--inline create-account">Sign up today for:</a>
        </p>
        <h3 class="regular no--margin-b">
          - A faster checkout experience<br>
          - Order status and history<br>
          - Easy access to custom quotes
        </h3>
      </div>
    </div>
    <div class="modal__body modal__body--fill">
      <div id="signupForm" class="w-form">
        <form v-if="!isSuccess" id="wf-form-signupForm" name="wf-form-signupForm" method="get" aria-label="signupForm">
          <div class="field-group__row">
            <div class="text-input col--1-of-2 first">
              <input v-model="firstName" name="firstName" id="firstName" type="text" class="text-input__field labeled w-input" autocomplete="off" maxlength="256" required="" style="width: 312px;">
              <div class="label w-embed"><label class="text-input__label" for="firstName">First Name</label></div>
            </div>
            <div class="text-input col--1-of-2 last">
              <input v-model="lastName" name="lastName" id="lastName" type="text" class="text-input__field labeled w-input" autocomplete="off" maxlength="256" placeholder="" required="" style="width: 312px;">
              <div class="label w-embed"><label class="text-input__label" for="lastName">Last Name</label></div>
            </div>
          </div>
          <div class="text-input">
            <input v-model="email" id="email" name="email" type="email" class="text-input__field labeled w-input" autocomplete="off" maxlength="256" placeholder="" required="" style="width: 640px;">
            <div class="label w-embed"><label class="text-input__label" for="email">Email</label></div>
          </div>
          <div class="text-input">
            <input v-model="password" id="password" name="password" type="password" class="text-input__field labeled w-input" autocomplete="off" maxlength="256" placeholder="" required="">
            <div class="label w-embed"><label class="text-input__label" for="password">Password</label></div>
          </div>
          <div class="text-input">
            <input v-model="confirmPassword" id="confirmPassword" name="confirmPassword" type="password" class="text-input__field labeled w-input" autocomplete="off" maxlength="256" placeholder="" required="">
            <div class="label w-embed"><label class="text-input__label" for="confirmPassword">Confirm Password</label></div>
          </div>
          <div class="field-group">
            <div class="ts--callout">Where did you first hear about Everhem?</div>
            <div class="field-group__row control-group">
              <div class="field-group__column">
                <label v-for="(source, index) in firstColumnOfSources" :key="index" class="radio w-radio">
                  <input v-model="whereDidYouHearAboutUs" type="radio" name="source" :id="source.id" required="" class="w-form-formradioinput w-radio-input" :value="source.id">
                  <span :for="source.id" class="radio__label pretext w-form-label">{{ source.name }}</span>
                </label>                
              </div>
              <div class="field-group__column">
                <label v-for="(source, index) in secondColumnOfSources" :key="index" class="radio w-radio">
                  <input v-model="whereDidYouHearAboutUs" type="radio" name="source" :id="source.id" required="" class="w-form-formradioinput w-radio-input" :value="source.id">
                  <span :for="source.id" class="radio__label pretext w-form-label">{{ source.name }}</span>
                </label>                
              </div>
            </div>
            <div class="ts--callout">Sign up for our email newsletter to receive updates on product launches, brand news &amp;&nbsp;special events.</div>
            <div class="field-group__row control-group">
              <label class="radio w-radio"><input v-model="signupToNewsletter" type="radio" name="newsletterOptIn" id="yes" required="" class="w-form-formradioinput w-radio-input" value="yes"><span for="yes" class="radio__label pretext w-form-label">Sign Up</span></label>
              <label class="radio w-radio"><input v-model="signupToNewsletter" type="radio" name="newsletterOptIn" id="no" required="" class="w-form-formradioinput w-radio-input" value="no"><span for="no" class="radio__label pretext w-form-label">No thanks</span></label>
            </div>
          </div>
          <div class="form__footer">
            <a @click="submitForm" :class="submitButtonCss">{{ submitButtonText }}</a>
          </div>
        </form>
        <div v-if="isSuccess" class="form__success w-form-done" tabindex="-1" role="region" aria-label="signupForm success">
          <div>
            <img src="https://cdn.prod.website-files.com/64da92de170fa515899c18fc/64da92de170fa515899c19e3_icon-success.svg" alt="" class="icon-success">
            <div class="form__success-message large">Thank you for creating an account. We are very excited to work with you!</div>
            <a href="/old-home" class="trigger--pageout button button--mt w-button">Meanwhile, Keep Customizing</a>
          </div>
        </div>
        <div v-if="error" class="w-form-fail" tabindex="-1" role="region" aria-label="signupForm failure">
          <div>{{ error }}</div>
        </div>
      </div>
    </div>
  </div>
</template>